.pricing_table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  margin: auto;

  text-align: center;
}

.pricing_table thead tr {
  background-color: #f1f1f1;
  text-align: left;
  font-weight: bold;
}

.pricing_table th,
.pricing_table td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
  font-weight: 500;
  text-align: center;
}
.pricing_table tr th {
  font-weight: 600;
}

.pricing_table tbody tr {
  border-bottom: 1px solid #dddddd;
}
