.sectionContainer {
}

.container {
  padding: 20px 20px 18px 20px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 16px;
}
.breadcrumbsContainer {
  display: "flex";
  flex-flow: "row";
  align-items: "center";
  justify-content: "space-between";
}
.backDiv {
  margin-bottom: "24px";
}

.headerSection {
  background-color: #e9eeff;
  padding: 12px 12px 16px 12px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.headerContent {
  display: flex;
  gap: 8px;
}

.headerText {
  color: #1a1a1a;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.subtext {
  color: #666;
  font-size: 12px;
  margin: 8px 0 12px 0;
  font-weight: 400;
}

.buttonGroup {
  display: flex;
  gap: 12px;
  align-items: center;
}

.buyButton {
  background-color: #0040ff !important;
  color: white !important;
  text-transform: none !important;
  padding: 0px 16px !important;
  font-weight: 500 !important;
}

.trialButton {
  color: #0040ff !important;
  text-transform: none !important;
  padding: 6px 16px !important;
  font-weight: 500 !important;
}

.howItWorks {
  border-radius: 8px;
  overflow: hidden;
}

.accordionHeader {
  display: flex;
  align-items: center;
  gap: 12px;
}

.featureList {
  padding-left: 20px;
  margin: 16px 0;
}

.featureItem {
  margin-bottom: 8px;
  color: #333;
}

.termsSection {
  margin-top: 20px;
  padding: 12px 20px 10px 20px;
  background: #ffffff;
  border-radius: 8px;
}

.termsText {
  color: #666;
  font-size: 14px;
}

.termsLink {
  color: #0040ff;
  text-decoration: none;
}
.noMargin .MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
}
.accordionSummary {
  padding: 12px 20px;
}
