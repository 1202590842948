::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}
::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-button {
  width: 10px;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-control {
  width: 100% !important;
  border-radius: 4px;
}

.form-control:focus {
  border-color: #0057ff !important;
  box-shadow: 0 0 0 1px #0057ff !important;
}

.form-control.invalid-number {
  border-color: #d32f2f !important;
}

.special-label {
  top: -9px !important;
  font-size: 12px !important;
  color: #00000088 !important;
}
.form-control:focus ~ .special-label {
  color: #0057ff !important;
}
.react-tel-input :disabled {
  color: #00000061 !important;
  border: 1px solid #cacaca !important;
}
.react-tel-input .special-label {
  display: inline;
}
