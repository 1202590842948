.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}
.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}
.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}
.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #fff;
}
.slick-prev {
  left: -25px;
}
[dir="rtl"] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  margin-top: 10px;
  width: 100%;
  list-style: none;
  text-align: center;
  border-radius: 50%;
  bottom: unset;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 8px;
  width: 85px;
  height: 64px;
  margin-top: 16px;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
}

.slick-dots li button {
  padding: 0px;
  width: 10px;
  height: 10px;
  background: #c4c4c4;
  border: 1px solid;
  border-radius: 50%;
  color: transparent;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  opacity: 0.25;
  color: #c4c4c4;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #c4c4c4;
}

.slick-slide div {
  outline: none;
}

.gm-style .gm-style-iw-c {
  display: none;
}

.gm-style .gm-style-iw-t::after {
  display: none;
}

.slick-thumb {
  margin-top: -62px;
}

@media screen and (min-width: 600px) {
  .slick-thumb {
    margin-top: -30px;
  }
}

@media screen and (min-width: 1400px) {
  .slick-thumb {
    margin-top: 0px;
  }
}

/*# sourceMappingURL=slick-theme.min.css.map */
